import ProductCard from 'pwasaasComponent/ProductCard/ProductCard.component';

/** @namespace Vvep/Component/ProductCard/Component/ProductCardComponent */
export class ProductCardComponent extends ProductCard {
    renderConfigurableOptions() {
        return null;
    }
}

export default ProductCardComponent;
